
import { Options, Vue } from "vue-property-decorator";
import Page from "@views/page/components/page.vue";
import LogsViewFilter from "@/views/components/filter-component/logs-view-filter.vue";
import WsActiveLogsView from "./ws-active-logs-view.vue";
import FilterModel from "../../components/filter-model";
import moment from "moment";
import { LocationQueryRaw } from "vue-router";
import PageRequest from "@/dataSource/api/pagination-helper/lib/PageRequest";

@Options<OtherLogsViewPage>({
  name: "text-logs-page",
  components: {
    Page,
    LogsViewFilter,
    WsActiveLogsView,
  },
})
export default class OtherLogsViewPage extends Vue {
  pagination: PageRequest;
  filterModel: FilterModel = new FilterModel();

  mounted(): void {
    this.getFilterFromQuery();
    // (this.$refs.page as WsActiveLogsView)?.refresh();
  }
  created() {
    this.getFilterFromQuery();
  }

  getFilterFromQuery() {
    let newFilter: FilterModel = new FilterModel();

    for (let key in this.$route.query) {
      switch (key) {
        case "DateFrom":
          newFilter.DateFrom = moment(this.$route.query[key]).toDate();
          break;
        case "DateTo":
          newFilter.DateTo = moment(this.$route.query[key]).toDate();
          break;
        default:
          newFilter[key] = this.$route.query[key];
      }
    }

    this.filterModel.update(newFilter);
    (this.$refs.page as WsActiveLogsView)?.refresh();
  }

  clear() {
    this.onclickFilterChange();
  }

  onclickFilterChange() {
    this.filterModel.SkipPage = 0;
    this.filterModel.TakePage = 20;
    this.updateQuery();
  }

  updatePaginationQuery(page: PageRequest) {
    this.filterModel.SkipPage = page.Skip;
    this.filterModel.TakePage = page.Take;

    this.updateQuery();
  }

  updateQuery() {
    let params: Object = {
      DateFrom: moment(this.filterModel.DateFrom).format("MM.DD.YY HH:mm:ss"),
      DateTo: moment(this.filterModel.DateTo).format("MM.DD.YY HH:mm:ss"),
      LogLevel: this.filterModel.LogLevel,
      ShortMessage: this.filterModel.ShortMessage,
      SkipPage: this.filterModel.SkipPage ? this.filterModel.SkipPage : "0",
      TakePage: this.filterModel.TakePage,
      LoggerName: this.filterModel.LoggerName,
      ProjectLogGroupId: this.filterModel.ProjectLogGroupId,
      Ip: this.filterModel.Ip,
      Url: this.filterModel.Url,
      SessionToken: this.filterModel.SessionToken,
      RequestId: this.filterModel.RequestId,
    };

    let query: LocationQueryRaw = {};

    Object.keys(params).forEach((key) => {
      let value = params[key];

      if (value != null && value != undefined && value.toString() != "") {
        query[key] = value;
      }
    });
    (this.$refs.page as WsActiveLogsView).refresh();
    // this.$router.push({ name: this.$route.name, query: query });
  }

  addMinutes(date, minutes) {
    return new Date(new Date(date).getTime() + minutes * 60000);
  }
}
